import React from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import { Root } from './containers'
import { AppLauncher } from './pages'
import * as routes from './constants/routes'
import emptyBrandingSettings from './constants/emptyBrandingSettings'

const RouterRoot = props => {
  const newrelic = window.NREUM
  if (newrelic) {
    newrelic.noticeError('Branding assets are ok.', {
      severity: 'warning'
    })
  }
  const branding = props.brandingSettings || emptyBrandingSettings
  const loginProps = (({
    csrf,
    localLoginEnabled,
    identityProviders,
    showCaptcha,
    showGoogleFooterLink,
    disclaimerEnabled,
    siteKey,
    brandAssetsURL,
    talkdeskIdAssetsURL,
    marketingBannerURL,
    marketingBannerTimeout,
    metadata,
    sidebannerEnabled
  }) => ({
    csrf,
    localLoginEnabled,
    identityProviders,
    showCaptcha,
    showGoogleFooterLink,
    disclaimerEnabled,
    siteKey,
    brandAssetsURL,
    talkdeskIdAssetsURL,
    marketingBannerURL,
    marketingBannerTimeout,
    brandingSettings: branding,
    metadata,
    sidebannerEnabled
  }))(props)

  const launcherProps = (({ metadata, brandAssetsURL }) => ({
    metadata,
    brandAssetsURL,
    brandingSettings: branding
  }))(props)

  return (
    <BrowserRouter>
      <React.Fragment>
        <Route
          path={routes.LOGIN_ROOT}
          render={() => <Root {...loginProps} />}
        />
        <Route
          path={routes.APP_LAUNCHER}
          render={() => <AppLauncher {...launcherProps} />}
        />
      </React.Fragment>
    </BrowserRouter>
  )
}

export default RouterRoot
